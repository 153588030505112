import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useTracker from "hooks/useTracker";
import { translate } from "locale/formatter";
import {
  continuePayViaUPI,
  getCustomerSummary,
  getStatusOfPayment,
} from "services/paynow";
import { path } from "utils/const";
import { getUserData } from "utils/localStorage";
import { notifySnackbar } from "utils/notify";
import {
  CONSTANT_REQUEST_DATA,
  getPaymentLink,
  NUMBER_OF_ATTEMPTS,
  PAYMENT_METHOD,
  PAYMENT_MODE,
  PAYMENT_OPTION,
  PAYMENT_STATUS,
} from "utils/payNow";

import AppButton from "components/Button";
import DrawerSheet from "components/Drawer";

import Header from "./Header";
import PaymentInfo from "./PaymentInfo";
import PaymentWindow from "./PaymentWindow";
import { useStyles } from "./styled";

const PayNowModal = ({
  open,
  setOpenPayNow,
  isMandiActive,
  mandiName,
  mandiCreditUnitId,
  overdueAmount,
  outStandingAmount,
}) => {
  const [customerData, setCustomerData] = useState({});
  const [moveToNextPage, setMoveToNextPage] = useState(false);
  const [paymentOption, setPaymentOption] = useState(PAYMENT_OPTION.OVERDUE);
  const [paymentMethod, setPaymentMethod] = useState(PAYMENT_METHOD.GPAY.value);
  const [enterAmountVal, setEnterAmountVal] = useState("");
  const [paymentMode, setPaymentMode] = useState("");
  const [payableAmount, setPayableAmount] = useState("");
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const { me } = useSelector((state) => state.app);

  const { headerMargin, mainContainer, button } = useStyles();

  const trackUser = useTracker();

  const { INITIATOR_TYPE, BRAND, GENERATED_VIA } = CONSTANT_REQUEST_DATA;
  const { ENTER_AMOUNT, OUTSTANDING, OVERDUE } = PAYMENT_OPTION;
  const { PENDING, FAILED, SUCCESS } = PAYMENT_STATUS;
  const { UPI } = PAYMENT_MODE;

  const getCustomerDetails = () => {
    getCustomerSummary(me.customerId, { is_fruitx: true }).then((resp) => {
      setCustomerData(resp);
    });
  };

  useEffect(() => {
    if (open) {
      getCustomerDetails();
    }
  }, [open]);

  const continueToPay = () => {
    switch (paymentOption) {
      case OUTSTANDING:
        setPayableAmount(outStandingAmount);
        break;
      case OVERDUE:
        setPayableAmount(overdueAmount);
        break;
      case ENTER_AMOUNT:
        setPayableAmount(enterAmountVal);
        break;
      default:
        return;
    }
    setMoveToNextPage(true);
  };

  const checkPaymentStatus = (id) => {
    let attempts = 1;
    const maxAttempts = NUMBER_OF_ATTEMPTS;
    const interval = 10000;

    const updatePollingScreenState = (status) => {
      navigate(path.paymentStatus, {
        state: {
          payableAmount,
          paymentStatus: status,
          mandi_name: mandiName,
        },
      });
    };

    updatePollingScreenState(PENDING);

    const intervalId = setInterval(() => {
      if (attempts >= maxAttempts) {
        clearInterval(intervalId);
        updatePollingScreenState(FAILED);
        return;
      }

      getStatusOfPayment(id)
        .then((res) => {
          if (res?.success) {
            clearInterval(intervalId);
            updatePollingScreenState(SUCCESS);
          } else {
            attempts += 1;
            updatePollingScreenState(PENDING);
          }
        })
        .catch(() => {
          attempts += 1;
          updatePollingScreenState(PENDING);
        });
    }, interval);
  };

  const payWithUpi = () => {
    setLoader(true);
    const requestData = {
      va_pay_request: {
        amount: +payableAmount,
        vpa: customerData.vpas[0],
        initiator_id: mandiCreditUnitId,
        initiator_type: INITIATOR_TYPE,
        brand: BRAND,
        generated_via: GENERATED_VIA,
      },
    };
    continuePayViaUPI(requestData).then((response) => {
      window.open(getPaymentLink(paymentMethod, customerData, +payableAmount));
      checkPaymentStatus(response?.id);
      setLoader(false);
    });
  };

  const onClickOkay = () => {
    setOpenPayNow(false);
    setMoveToNextPage(false);
    setEnterAmountVal("");
  };

  const handleButtonClick = () => {
    if (getUserData()?.ghostLogin)
      return notifySnackbar(
        "You are not allowed to perform this action as a ghost user.",
        "error"
      );
    if (!moveToNextPage) {
      continueToPay();
    } else if (moveToNextPage && paymentMode === UPI) {
      payWithUpi();
    } else {
      onClickOkay();
      trackUser("payment_above_one_lakh", {
        Amount: payableAmount,
      });
    }
  };

  const buttonValue =
    !moveToNextPage || (moveToNextPage && paymentMode === UPI)
      ? "continue"
      : "ok";

  const disableContinueButton =
    (paymentOption === ENTER_AMOUNT && !enterAmountVal) || loader;

  return (
    <DrawerSheet
      open={open}
      onClose={() => setOpenPayNow(false)}
      drawerHeight="65vh"
    >
      <Header
        moveToNextPage={moveToNextPage}
        setMoveToNextPage={setMoveToNextPage}
        isMandiActive={isMandiActive}
        mandiName={mandiName}
        setOpenPayNow={setOpenPayNow}
      />

      <div className={`${headerMargin} ${mainContainer}`}>
        {!moveToNextPage ? (
          <PaymentInfo
            paymentOption={paymentOption}
            setPaymentOption={setPaymentOption}
            outStandingAmount={outStandingAmount}
            overdueAmount={overdueAmount}
            enterAmountVal={enterAmountVal}
            setEnterAmountVal={setEnterAmountVal}
            setPaymentMode={setPaymentMode}
          />
        ) : (
          <PaymentWindow
            paymentMode={paymentMode}
            paymentMethod={paymentMethod}
            setPaymentMethod={setPaymentMethod}
            payableAmount={payableAmount}
            customerData={customerData}
          />
        )}
      </div>

      <div className={headerMargin}>
        <AppButton
          variant="contained"
          size="large"
          className={button}
          color="primary"
          disabled={disableContinueButton}
          onClick={handleButtonClick}
        >
          <b>{translate(buttonValue)}</b>
        </AppButton>
      </div>
    </DrawerSheet>
  );
};

export default PayNowModal;
