import { lazy, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Home, ImportContacts } from "@mui/icons-material";
import { Box } from "@mui/material";
import HomePageRedirection from "atomic-components/HomePageRedirection";
import { AUCTION_STATUS } from "const/auctionStatus";
import { USERROLE } from "const/userRole";
import useAppAccess from "hooks/useAppAccess";
import useMoEngage from "hooks/useMoEngage";
import useTracker from "hooks/useTracker";
import { translate } from "locale/formatter";
import { getBidderAssociatedAuctions } from "utils";
import { path } from "utils/const";
import { HOME_SCREEN_EVENTS, MY_BOOKS_EVENTS } from "utils/events";
import { useFirestore } from "utils/firebaseUtils/hooks/useFirestore";
import { getMandiId, getPrivacyPolicy, setMandiList } from "utils/localStorage";

import TransactionListPerMandi from "./my-transactions/TransactionListPerMandi";
import MyTransactions from "./my-transactions";
import { PaperComponent, TabButtonsStyle } from "./styled";

const TodaysMyBuys = lazy(() => import("./todaysMyBuys"));
const MyBuys = lazy(() => import("./myBuys"));
const Auth = lazy(() => import("pages/auth"));
const BidderPlatform = lazy(() => import("pages/bidder/platform"));
const MyDetails = lazy(() => import("./myDetails"));
const BidderDashboardWrapper = lazy(() => import("./dashboard"));
const Catalogue = lazy(() => import("pages/catalogue"));
const LotListingPage = lazy(() => import("pages/catalogue/lotListingPage"));
const WishListPage = lazy(() => import("pages/catalogue/wishlist"));
const WishlistPhotoViewer = lazy(() =>
  import("pages/catalogue/wishlist/components/wishlistPhotoViewer")
);
const PollingScreen = lazy(() =>
  import("pages/bidder/my-transactions/payNowModal/PollingScreen")
);
const ServiceAgreement = lazy(() =>
  import("../../atomic-components/TermsAndConditions/serviceAgreement")
);

const ServiceSchedule = lazy(() =>
  import("../../atomic-components/TermsAndConditions/schedule")
);
const TermsAndCondtionPage = lazy(() =>
  import("../../atomic-components/TermsAndConditions")
);

const BidderRoutes = () => {
  const [hasAccessChanged, unsetAccesChanged] = useAppAccess();
  const navigate = useNavigate();
  const { me } = useSelector((state) => state.app);
  const auctionDocs = useFirestore("auc_auctions").docs;
  const { pathname } = useLocation();
  const trackUser = useTracker();
  const tracker = useMoEngage();

  const [showRedirectPopup, setShowRedirectPopup] = useState(false);

  useEffect(() => {
    if (hasAccessChanged) {
      setShowRedirectPopup(true);
    }
  }, [hasAccessChanged]);

  const handleOnPopupClosed = () => {
    unsetAccesChanged();
    setShowRedirectPopup(false);
  };

  const todaysAuction = getBidderAssociatedAuctions(auctionDocs, me).find(
    (item) => item.mandi_id === getMandiId()
  );

  if (
    todaysAuction &&
    todaysAuction?.status !== AUCTION_STATUS.LIVE &&
    window.location.pathname.includes("bidder-platform")
  ) {
    navigate(path.bidderDashboard);
  }

  useEffect(() => {
    if (pathname === path.bidderDashboard) {
      tracker(HOME_SCREEN_EVENTS.HOME_SCREEN_VIEWED);
    }
  }, [pathname]);

  const BottomTabRoutes = [path.bidderDashboard, path.myBooks];

  const isPrivacyPolicyAccepted = getPrivacyPolicy();

  return (
    <>
      {showRedirectPopup && (
        <HomePageRedirection onClose={handleOnPopupClosed} />
      )}

      {isPrivacyPolicyAccepted ? (
        <Routes>
          {/* Ability to access login page despite already logged in. Required for Cypress to switch users during testing.. */}
          {/* <Route path="/login" element={<Auth />} /> */}
          <Route
            path={path.bidderDashboard}
            element={<BidderDashboardWrapper />}
          />
          <Route path={path.bidderPlatform} element={<BidderPlatform />} />
          <Route path={path.todaysMyBuys} element={<TodaysMyBuys />} />
          <Route path={path.mybuys} element={<MyBuys />} />
          <Route path={path.details} element={<MyDetails />} />
          <Route path={path.catalogue} element={<Catalogue />} />
          <Route path={path.lotListing} element={<LotListingPage />} />
          <Route
            path={path.wishlistPhotoViewer}
            element={<WishlistPhotoViewer />}
          />
          <Route path={path.wishlist} element={<WishListPage />} />
          <Route path={path.myBooks} element={<MyTransactions />} />
          <Route
            path={path.myBuysTransactions}
            element={<TransactionListPerMandi />}
          />
          <Route
            path={path.myPaymentsTransactions}
            element={<TransactionListPerMandi />}
          />
          <Route path={path.paymentStatus} element={<PollingScreen />} />
          {/* <Route path={path.serviceAgreement} element={<ServiceAgreement />} />
          <Route path={path.serviceSchedule} element={<ServiceSchedule />} /> */}
          <Route path="*" element={<Navigate to={path.bidderDashboard} />} />
        </Routes>
      ) : (
        <Routes>
          <Route path={path.serviceAgreement} element={<ServiceAgreement />} />
          <Route path={path.serviceSchedule} element={<ServiceSchedule />} />
          <Route
            path={path.termsAndConditions}
            element={<TermsAndCondtionPage />}
          />
          <Route path="*" element={<Navigate to={path.termsAndConditions} />} />
        </Routes>
      )}
      {me?.role === USERROLE.BIDDER &&
        BottomTabRoutes.includes(pathname) &&
        isPrivacyPolicyAccepted && (
          <PaperComponent elevation={24}>
            <Box display="flex" justifyContent="space-around">
              <TabButtonsStyle
                to={path.bidderDashboard}
                pathname={pathname}
                path={path.bidderDashboard}
                onClick={() => {
                  trackUser("home_tab_selected");
                  tracker(HOME_SCREEN_EVENTS.HOME_BUTTON_CLICK);
                }}
              >
                <Home fontSize="large" />
                <b>{translate("home")}</b>
              </TabButtonsStyle>
              <TabButtonsStyle
                to={path.myBooks}
                pathname={pathname}
                path={path.myBooks}
                onClick={() => {
                  trackUser("my_books_tab_selected");
                  tracker(HOME_SCREEN_EVENTS.MY_BOOKS_BUTTON_CLICK);
                  tracker(MY_BOOKS_EVENTS.MANDI_BOOKS_CARD_VIEWED);
                }}
              >
                <ImportContacts fontSize="large" />
                <b>{translate("my_books")}</b>
              </TabButtonsStyle>
            </Box>
          </PaperComponent>
        )}
    </>
  );
};

export default BidderRoutes;
