import { Link } from "react-router-dom";
import { useTheme } from "@mui/styles";

const TermsAndConditionContent = ({ customerName = "POC Name" }) => {
  const { colors } = useTheme();

  return (
    <div style={styles.container}>
      <p>
        <strong>Parties:</strong>
      </p>
      <p>
        <strong>Company:</strong> FruitX (Chifu Agritech Private Limited)
      </p>
      <p style={{ textTransform: "capitalize" }}>
        <strong>Customer: </strong>
        {customerName}
      </p>

      <ol style={styles.list}>
        <li>
          The Customer must provide required KYC documents for Onboarding and a
          security deposit in the form of a signed, post-dated/Un-dated cheque
          for starting a Credit Limit.
        </li>
        <li>
          If any Customer does not provide the required Post Dated/Un-dated
          Cheque within 4 Days, then their credit limit will be reduced and they
          will not be allowed to participate in Auctions.
        </li>
        <li>
          All goods must be inspected before bidding. No complaints regarding
          quality, specifications, or other matters will be entertained once the
          Delivery of goods is complete.
        </li>
        <li>
          Invoices must be acknowledged on the same day of issuance via
          signature or OTP.
        </li>
        <li>
          The Customer shall maintain decorum and avoid any nuisance at the
          mandi. Violations may result in disqualification from auctions.
        </li>
        <li>
          Payments must be made directly into the FruitX’s official bank account
          (or preferably to your respective Virtual Account). Payments to any
          individual's personal account are strictly prohibited and would not be
          acknowledged.
        </li>
        <li>
          Cash payments must be made only to the assigned finance personnel.
          Please enquire about the same with the Auctioneer if not aware.
        </li>
        <li>
          Payments can be made via UPI, RTGS, IMPS, NEFT, or cash. Cash
          transactions exceeding INR 1,99,000 are strictly not allowed. This
          includes the cash deposition made to the FruitX’s Account (viz. Direct
          Deposit amount in FruitX Account can’t exceed INR 1,99,000 also).
        </li>
        <li>
          Credit purchases must be cleared within the agreed credit period.
        </li>
        <li>
          Payments for cash-and-carry transactions must be completed on the same
          day before leaving the Mandi.
        </li>
        <li>
          A Gate Pass approved by the Finance/Ops team will be necessary for
          seamless exit from the Mandi.
        </li>
        <li>Any delayed Payment will incur a monthly penalty.</li>
        <li>
          The Customer can purchase goods from other Mandis only and only after
          clearing the outstanding dues with FruitX as per the mutually agreed
          terms with the company.
        </li>
        <li>
          The Customer must acknowledge the ledger on a monthly basis via
          signature/OTP. If not signed, then the ledger and outstanding amounts
          as provided by the Company will be considered final and binding.
        </li>
        <li>
          If the Customer is not physically present in the Mandi, all the above
          conditions shall equally apply to the Customer's authorized
          representative participating in the auction on their behalf.
        </li>
        <li>
          The goods will be delivered to the Customer to whom all the above
          conditions are accepted.
        </li>
      </ol>

      <p style={styles.agreementNote}>
        This Agreement ensures clear expectations for both the parties and
        safeguards their respective interests. For more details, please refer to{" "}
        (
        <Link
          to={{
            pathname: "/service/service-agreement",
            search: "?readOnly=true",
          }}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "underline", color: colors.primary }}
        >
          Linked Agreement
        </Link>
        {"  "},
        <Link
          to={{
            pathname: "/service/schedule",
            search: "?readOnly=true",
          }}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "underline", color: colors.primary }}
        >
          Privacy Policy
        </Link>
        )
      </p>

      <hr style={styles.divider} />
      <p>
        <strong>पक्ष:</strong>
      </p>
      <p>
        <strong>कंपनी:</strong> FruitX (चिफु एग्रीटेक प्राइवेट लिमिटेड)
      </p>
      <p>
        <strong>ग्राहक:</strong> {customerName}
      </p>

      <ol style={styles.list}>
        <li>
          ग्राहक को ऑनबोर्डिंग के लिए आवश्यक KYC दस्तावेज और क्रेडिट सीमा शुरू
          करने के लिए एक हस्ताक्षरित, पोस्ट-डेटेड/अनडेटेड चेक के रूप में सुरक्षा
          जमा करना होगा।
        </li>
        <li>
          यदि ग्राहक 4 दिनों के भीतर आवश्यक पोस्ट डेटेड/अनडेटेड चेक प्रदान नहीं
          करता है, तो उसकी क्रेडिट सीमा कम कर दी जाएगी और उसे नीलामियों में भाग
          लेने की अनुमति नहीं दी जाएगी।
        </li>
        <li>
          सभी वस्तुओं का नीलामी से पहले निरीक्षण करना आवश्यक है। डिलीवरी पूरी
          होने के बाद गुणवत्ता, विनिर्देश, या अन्य मामलों को लेकर कोई शिकायत
          स्वीकार नहीं की जाएगी।
        </li>
        <li>
          चालान को जारी होने वाले दिन ही हस्ताक्षर या ओटीपी के माध्यम से स्वीकार
          करना होगा।
        </li>
        <li>
          ग्राहक को मंडी में शिष्टाचार बनाए रखना होगा और किसी भी प्रकार की
          गड़बड़ी से बचना होगा। उल्लंघन होने पर नीलामी में भाग लेने से अयोग्यता
          हो सकती है।
        </li>
        <li>
          भुगतान सीधे FruitX के आधिकारिक बैंक खाते (या ग्राहक के वर्चुअल खाते)
          में किया जाना चाहिए। किसी भी व्यक्ति के व्यक्तिगत खाते में भुगतान करना
          सख्त वर्जित है और इसे स्वीकार नहीं किया जाएगा।
        </li>
        <li>नकद भुगतान केवल नियुक्त वित्तीय कर्मियों को ही किया जा सकता है।</li>
        <li>
          भुगतान UPI, RTGS, IMPS, NEFT, या नकद के माध्यम से किया जा सकता है।
          ₹1,99,000 से अधिक के नकद लेनदेन की अनुमति नहीं है।
        </li>
        <li>क्रेडिट लेनदेन को सहमत समय सीमा के भीतर पूरा करना अनिवार्य है।</li>
        <li>
          नकद में सामान ले जाने के लिए, भुगतान मंडी छोड़ने से पहले, उसी दिन पूरा
          किया जाना चाहिए।
        </li>
        <li>
          मंडी से बाहर निकलने के लिए वित्त/ऑप्स टीम द्वारा अनुमोदित गेट पास
          आवश्यक होगा।
        </li>
        <li>किसी भी विलंबित भुगतान पर मासिक दंड लागू किया जाएगा।</li>
        <li>
          ग्राहक केवल FruitX के साथ बकाया राशि को साफ करने के बाद ही अन्य
          मंडियों से माल खरीद सकते हैं।
        </li>
        <li>
          ग्राहक को मासिक आधार पर लेजर को हस्ताक्षर/ओटीपी के माध्यम से स्वीकार
          करना होगा। यदि हस्ताक्षर नहीं किया जाता है, तो कंपनी द्वारा प्रदान की
          गई लेजर और बकाया राशि अंतिम और बाध्यकारी मानी जाएगी।
        </li>
        <li>
          यदि ग्राहक मंडी में शारीरिक रूप से उपस्थित नहीं होता है, तो उपरोक्त
          सभी शर्तें उसके अधिकृत प्रतिनिधि पर भी लागू होंगी।
        </li>
        <li>
          वे वस्तुएं केवल उसी ग्राहक को वितरित की जाएंगी, जिन्होंने उपरोक्त सभी
          शर्तों को स्वीकार किया है।
        </li>
      </ol>

      <p style={styles.agreementNote}>
        यह समझौता दोनों पक्षों के लिए स्पष्ट अपेक्षाओं को सुनिश्चित करता है और
        उनके संबंधित हितों की सुरक्षा करता है। अधिक जानकारी के लिए कृपया
        <Link
          to={{
            pathname: "/service/service-agreement",
            search: "?readOnly=true",
          }}
          target="_blank"
          rel="noopener noreferrer"
          style={styles.link}
        >
          संलग्न समझौता
        </Link>
        और
        <Link
          to={{ pathname: "/service/schedule", search: "?readOnly=true" }}
          target="_blank"
          rel="noopener noreferrer"
          style={styles.link}
        >
          गोपनीयता नीति
        </Link>{" "}
        देखें।
      </p>
    </div>
  );
};

const styles = {
  container: {
    padding: "16px 20px 20px 20px",
    fontFamily: "Arial, sans-serif",
    lineHeight: "1.6",
    backgroundColor: "#f9f9f9",
    borderRadius: "8px",
    maxWidth: "800px",
    margin: "0 auto",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  },
  title: {
    fontSize: "28px",
    textAlign: "center",
    marginBottom: "20px",
    color: "#333",
  },
  list: {
    marginLeft: "20px",
    listStyleType: "disc",
  },
  agreementNote: {
    marginTop: "20px",
  },
  link: {
    color: "#007BFF",
    textDecoration: "underline !important",
  },
  button: {
    display: "block",
    width: "100%",
    padding: "15px",
    fontSize: "16px",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    textAlign: "center",
    marginTop: "20px",
  },
  divider: {
    margin: "20px 0",
  },
};

export default TermsAndConditionContent;
