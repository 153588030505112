import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Capacitor } from "@capacitor/core";
import moengage from "@moengage/web-sdk";
import { MoECapacitorCore } from "capacitor-moengage-core";
import { getUserData } from "utils/localStorage";

const useMoEngage = () => {
  const { me } = useSelector((state) => state.app);
  const APP_ID = process.env.REACT_APP_MOENGAGE_APP_ID;
  const userData = getUserData();

  useEffect(() => {
    if (Capacitor.isNativePlatform() && !userData?.ghostLogin) {
      MoECapacitorCore.addListener("pushTokenGenerated", (data) => {
        console.log(
          `${
            this.tag
          } Received callback 'pushTokenGenerated',  data: ${JSON.stringify(
            data
          )}`
        );
      });
      MoECapacitorCore.addListener("pushClicked", (data) => {
        console.log(
          `${this.tag} Received callback 'pushClicked',  data: ${JSON.stringify(
            data
          )}`
        );
      });

      MoECapacitorCore.addListener("inAppCampaignShown", (data) => {
        console.log(
          `${
            this.tag
          } Received callback 'inAppCampaignShown',  data: ${JSON.stringify(
            data
          )}`
        );
      });

      MoECapacitorCore.addListener("inAppCampaignDismissed", (data) => {
        console.log(
          `${
            this.tag
          } Received callback 'inAppCampaignDismissed',  data: ${JSON.stringify(
            data
          )}`
        );
      });

      MoECapacitorCore.addListener("inAppCampaignClicked", (data) => {
        console.log(
          `${
            this.tag
          } Received callback 'inAppCampaignClicked',  data: ${JSON.stringify(
            data
          )}`
        );
      });

      MoECapacitorCore.addListener("inAppCampaignCustomAction", (data) => {
        console.log(
          `${
            this.tag
          } Received callback 'inAppCampaignCustomAction',  data: ${JSON.stringify(
            data
          )}`
        );
      });

      MoECapacitorCore.addListener("inAppCampaignSelfHandled", (data) => {
        console.log(
          `${
            this.tag
          } Received callback 'inAppCampaignSelfHandled',  data: ${JSON.stringify(
            data
          )}`
        );

        MoECapacitorCore.selfHandledShown(data);
        MoECapacitorCore.selfHandledClicked(data);
        MoECapacitorCore.selfHandledDismissed(data);
      });

      MoECapacitorCore.initialize({
        appId: APP_ID,
      });
      if (Capacitor.getPlatform() === "android") {
        MoECapacitorCore.setupNotificationChannelsAndroid();
      }
    }
  }, []);

  useEffect(() => {
    if (!userData?.ghostLogin) {
      if (Capacitor.isNativePlatform()) {
        MoECapacitorCore.initialize({
          appId: APP_ID,
        });
        MoECapacitorCore.setUniqueId({
          uniqueId: me.email,
          appId: APP_ID,
        });
        MoECapacitorCore.setEmailId({
          emailId: me.email,
          appId: APP_ID,
        });

        MoECapacitorCore.setFirstName({
          firstName: me.fullName,
          appId: APP_ID,
        });
        if (Capacitor.getPlatform() === "android") {
          MoECapacitorCore.requestPushPermissionAndroid();
          MoECapacitorCore.pushPermissionResponseAndroid({ isGranted: true });
          MoECapacitorCore.updatePushPermissionRequestCountAndroid({
            appId: APP_ID,
            count: 2,
          });

          MoECapacitorCore.setupNotificationChannelsAndroid();
        } else {
          MoECapacitorCore.registerForPush();
        }
      } else {
        moengage.add_email(me.email);
        moengage.add_unique_user_id(me.email);
        moengage.add_first_name(me.fullName);
      }
    }
  }, [me]);

  const tracker = (event, params) => {
    if (!userData?.ghostLogin) {
      if (Capacitor.isNativePlatform()) {
        MoECapacitorCore.trackEvent({
          eventName: event,
          appId: APP_ID,
        });
      } else {
        moengage.track_event(event, params);
      }
    }
  };

  return tracker;
};

export default useMoEngage;
