import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { App } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";

const AppUrlListener = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      try {
        App?.addListener("appUrlOpen", (event) => {
          const slug = event.url.split(".live").pop();
          if (slug) {
            navigate(slug);
          }
        });
        Capacitor?.addListener("appUrlOpen", (event) => {
          const slug = event.url.split(".live").pop();
          if (slug) {
            navigate(slug);
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
  }, []);

  useEffect(() => {
    App.addListener("appUrlOpen", (event) => {
      const { url } = event;
      const route = url.split("fruitx://")[1];
      if (route) {
        navigate(route);
      }
    });
  }, [navigate]);

  return <></>;
};

export default AppUrlListener;
