import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import Pills from "components/Pills";

const ConfirmationPopUp = ({
  header = "",
  open = false,
  content = "",
  primaryBtntext = "",
  onCloseHandler = () => {},
  primaryBtnHandler = () => {},
}) => {
  return (
    <Dialog open={open} onClose={onCloseHandler} fullWidth>
      <DialogTitle>{header}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions className="flex gap-3">
        {primaryBtntext && (
          <Pills handleOnClick={primaryBtnHandler}>{primaryBtntext}</Pills>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationPopUp;
