import { velynkService } from "./base";

export const continuePayViaUPI = (data, params) => {
  return velynkService.post(
    `cds/customer_va_payments/generate_va_pay_request_for_customer`,
    data,
    {
      params,
    }
  );
};

export const getStatusOfPayment = (id, params) => {
  return velynkService.get(
    `cds/customer_va_payments/check_va_qr_payment_status/${id}`,
    { params }
  );
};

export const getBankAccountDetails = (id, params) => {
  return velynkService.get(`customers/${id}/bank_details`, {
    params,
  });
};

export const getCustomerSummary = (id, params) => {
  return velynkService.get(`customer_app/${id}/customer_summary`, { params });
};
