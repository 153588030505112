import styled from "@emotion/styled";

export const Wrapper = styled.div(({ showText, colors }) => ({
  gap: "0.5rem",
  display: "flex",
  padding: "0.75rem",
  background: colors.lightSilver,
  alignItems: "center",
  borderRadius: "12px",
  justifyContent: "center",
  minWidth: showText === "true" ? "108px" : "",
}));

export const HeaderWrapper = styled.div(() => ({
  top: 0,
  width: "100%",
  zIndex: 999999,
  position: "sticky",
  height: "12%",
}));

export const Header = styled.div(({ colors = {} }) => ({
  background: `linear-gradient(180deg, ${colors.seaGreen} 0%, ${colors.offWhite2} 100%)`,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  cursor: "pointer",
  padding: "1.5rem",
}));
