import { Capacitor } from "@capacitor/core";
import styled from "@emotion/styled";
import { Box, Button, Paper } from "@mui/material";
import { green, grey } from "@mui/material/colors";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled as ComponentStyle } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { fonts } from "styles/AtomicStyles";

import { ImageIcons } from "components";

export const CardHeader = styled.div(() => ({
  display: "flex",
  alignItems: "center",
  height: "35px",
  justifyContent: "space-between",
  padding: "0 1rem",
  paddingBottom: 0,
}));

export const CardBody = styled.div(() => ({
  padding: "1rem",
}));

export const OnlineIndicator = styled.div(({ isOnline }) => ({
  marginRight: "0.5rem",
  width: 10,
  height: 10,
  backgroundColor: isOnline ? green[500] : grey[500],
  borderRadius: "50%",
  border: `2px solid ${isOnline ? green[300] : grey[500]}`,
}));

export const TotalSection = styled.div(() => ({
  display: "flex",
  justifyContent: "space-evenly",
}));

export const Divider = styled.div(() => ({
  marginTop: "0.5rem",
  borderLeft: "1px solid #e6e6e6",
  height: "4rem",
  left: "50%",
}));

export const PaymentContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
}));

export const ProductImage = styled(ImageIcons)`
  height: 24px;
  width: 24px;
`;

export const CreditProgressSection = styled.div(() => ({
  marginBottom: "0",
}));

export const BorderLinearProgress = ComponentStyle(LinearProgress)(
  ({ colors, value }) => ({
    marginTop: 6,
    marginBottom: 6,
    height: 6,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: colors.gray2,
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: value === 100 ? "red" : "#6EAAA3",
    },
  })
);
export const PaymentBtn = ComponentStyle(Button)(({ colors, disabled }) => ({
  color: `${disabled ? colors.black : colors.white} !important`,
  background: `${disabled ? colors.disabled1 : colors.orange} !important`,
  padding: "0.5rem 1.5rem",
  fontSize: "12px",
  fontWeight: "bold",
}));

export const ButtonComponent = styled.div(() => ({
  display: "flex",
  width: "100%",
}));
export const Header = styled.div(() => ({
  display: "flex",
  width: "100%",
}));

export const HeaderSection = ComponentStyle(Paper)(() => ({
  padding: "1rem",
  background: "#fff",
  borderRadius: 0,
  marginBottom: "2px",
  boxShadow: "0 4px 2px -2px rgba(0, 0, 0, 0.2)",
}));

export const OverdueBanner = ComponentStyle(Paper)(({ colors }) => ({
  background: colors.mud,
  padding: "0.75rem",
  display: "flex",
  justifyContent: "space-between",
  color: colors.red,
}));

export const SubSectionTotal = ComponentStyle(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

export const HeaderWrapper = styled.div(() => ({
  top: 0,
  width: "100%",
  zIndex: 999999,
  position: "sticky",
  borderBottom: "1px solid #EEEEEE",
  marginTop: Capacitor.getPlatform() === "ios" ? "50px" : 0,
}));

export const TransactionHeader = styled.div(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  cursor: "pointer",
  padding: "1.5rem",
}));

export const classes = makeStyles(({ colors }) => ({
  headerWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
    position: "sticky",
    zIndex: 999999,
    top: 0,
    background: colors.white,
    height: "8%",
    width: "100%",
    boxShadow: " 0px 1px 1px 0px rgba(0,0,0,0.14)",
  },
  textWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "1rem",
    width: "100%",
  },
  CreditRequest: {
    background: colors.mud,
    width: "50%",
    marginRight: "1rem",
    color: colors.primary,
    textTransform: "none",
    fontSize: "1.2rem",
  },
  payNowButton: {
    background: colors.primary,
    width: "50%",
    textTransform: "none",
    fontSize: "1.2rem",
  },
  centerAlignVertically: {
    minWidth: "18.5rem",
  },
  startAlignVertically: {
    display: "flex",
    alignItems: "center",
  },
  utilizedCredit: {
    position: "absolute",
    left: (progress) => `calc(${progress - 2}%)`,
    top: "-20px",
  },
  myBooksBg: {
    background: colors.white,
    height: "100%",
  },
  scrollContainer: {
    overflow: "scroll",
    height: "65%",
    backgroundColor: colors.smokeWhite,
    borderRadius: "20px 20px 0px 0px",
    padding: "0 .3rem",
  },
  activeMandi: {
    background: `linear-gradient(to right,${colors.purple},${colors.lightPurple})`,
    height: "62px",
  },
  inActiveMandi: {
    background: `linear-gradient(to right,${colors.grey},${colors.lightGrey})`,
    height: "62px",
  },
  dividerGradient: {
    height: "0.08rem",
    background: `linear-gradient(to right,rgb(211 212 213 / 20%),rgb(211 212 213 / 60%),rgb(211 212 213 / 20%))`,
  },
  payContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "1rem",
  },
  flexColContainer: {
    background: `${colors.lightPink}`,
    borderRadius: "15px",
    padding: ".3rem .5rem",
    paddingRight: "1rem",
    display: "flex",
    alignItems: "center",
    fontSize: "13px",
    justifyContent: "space-between",
  },
  outStandingAmountContainer: {
    backgroundColor: `${colors.lightgrey}`,
    borderRadius: "15px",
    padding: ".3rem 1rem",
    display: "flex",
    alignItems: "center",
    fontSize: "13px",
    justifyContent: "space-between",
  },
  perMandiCard: {
    background: colors.blueGradient,
    padding: ".3rem 1rem",
    display: "flex",
    alignItems: "center",
    fontSize: "13px",
    justifyContent: "space-between",
    borderRadius: "15px",
  },
  fontSize20: {
    fontSize: "20px !important",
  },
  outstandingAmt: {
    fontFamily: fonts.NOTO_SANS,
    fontWeight: 400,
  },
  lightText: {
    fontWeight: "100 !important",
    color: `${colors.secondaryLightGrey}!important`,
    fontSize: "9px !important",
  },
  mandiName: {
    fontFamily: "Noto Sans !important",
    fontSize: "14px !important",
    padding: "0.5rem 0",
    color: `${colors.grayText} !important`,
  },
  creditLimit: {
    color: `${colors.fadeGrey} !important`,
    fontWeight: "400 !important",
    fontSize: "7px !important",
    textAlign: "end",
  },
  creditPercentage: {
    color: `${colors.fadeGrey} !important`,
    fontWeight: "400 !important",
    fontSize: "8px !important",
    textAlign: "end",
  },
  overdueText: {
    fontSize: "10px !important",
    color: `${colors.greyTextColor} !important`,
    fontFamily: fonts.NOTO_SANS,
    fontWeight: "400 !important",
    margin: "0 1.5rem 0 .9rem",
  },
  outstandingText: {
    fontSize: "10px !important",
    color: `${colors.greyTextColor} !important`,
    fontFamily: fonts.NOTO_SANS,
    fontWeight: "400 !important",
    margin: "0 1.5rem 0 1.4rem",
  },
  textEnd: {
    textAlign: "end",
  },
  fontNoto: {
    fontFamily: `${fonts.NOTO_SANS} !important`,
  },
  cardBaground: {
    boxShadow: `0.5px 0.5px 0.5px 1px ${colors.whiteShadow} !important`,
    borderRadius: "1.5rem",
  },
  outStandingCardBaground: {
    background:
      "linear-gradient(89.87deg, rgba(209, 241, 238, 0.4) 2.06%, rgba(250, 250, 250, 0.4) 97.34%)",
    boxShadow: `0.5px 0.5px 0.5px 1px ${colors.whiteShadow} !important`,
  },
  drawerContainer: {
    height: "100%",
    padding: "1rem",
  },
  mainContainer: {
    height: "80%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  displayFlex: {
    display: "flex",
  },
  payNowText: {
    color: "white",
    textAlign: "center",
    margin: "auto 0",
    marginRight: "5px",
  },
  button: {
    marginTop: 2,
    background: colors.orange,
    width: "100%",
    fontSize: 16,
  },
  imageIcon: {
    height: "25px",
    width: "60px",
    margin: "auto",
  },
  viewKhataButton: {
    backgroundColor: colors.secondaryLightBlue,
    borderRadius: "16px 0% 0 0%",
    marginTop: "10px",
    color: `${colors.secondaryLightGrey} !important`,
    padding: ".7rem .9rem !important",
  },
  verticalLine: {
    borderLeft: `1px solid ${colors.grey}`,
    height: "55px",
    marginLeft: "2px",
  },
  logoImage: {
    width: "30px !important",
    height: "30px !important",
    borderRadius: "10px",
    margin: "0 .5rem",
  },
  fontSize13: {
    fontSize: "13px !important",
  },
  paymentButton: {
    boxShadow: `0px 0px 5.94px 0px ${colors.blackShadow}`,
  },
  viewKhataIcon: {
    fontSize: "8px",
    fontWeight: "bold",
    marginLeft: ".5rem",
  },
  overdueIcon: {
    width: "16px",
    height: "auto",
  },
  fontSize11: {
    fontSize: "11px",
  },
  fontSize8: {
    fontSize: "8px",
  },
}));
