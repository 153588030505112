/* eslint-disable no-async-promise-executor */
/* eslint-disable simple-import-sort/imports */
import { createSlice } from "@reduxjs/toolkit";
import { auth, firestore } from "utils/firebaseUtils/firebase";
import { userLogin } from "services/users";
import { savePrivacyPolicy, saveCustomerData } from "utils/localStorage";

const initialState = {
  checked: false,
  loggedIn: false,
  me: {},
  selectedTokenId: -1,
};

const slice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setMe: (state, { payload }) => ({
      me: {
        ...payload.me,
        isPolicyAccepted: payload.me?.isPolicyAccepted || false,
      },
      loggedIn: payload.loggedIn,
      checked: true,
    }),
    setLoggedIn: (state, { payload }) => ({
      loggedIn: payload,
    }),
    setSelectedToken: (state, { payload }) => ({
      ...state,
      selectedTokenId: payload.tokenId,
    }),
    setIsPolicyAccepted: (state, { payload }) => {
      return {
        ...state,
        me: {
          ...state.me,
          isPolicyAccepted: payload.isPolicyAccepted,
        },
      };
    },
  },
});

export const authenticate = () => (dispatch) => {
  auth.onAuthStateChanged(async (me) => {
    if (!me) {
      return dispatch(
        slice.actions.setMe({
          loggedIn: false,
          checked: true,
          me: {},
        })
      );
    }

    // get user from firestore
    const user = await firestore.collection("users").doc(me?.uid).get();
    // login
    return dispatch(
      slice.actions.setMe({
        loggedIn: user.exists,
        me: user.exists
          ? { id: me?.uid, emailVerified: me?.emailVerified, ...user.data() }
          : {},
        checked: true,
      })
    );
  });
};

const login =
  ({ email, password }) =>
  (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        const res = await userLogin({
          auction_user: {
            email,
            password,
          },
        });
        const { firebase_id_token } = res || {};
        const { user } = await auth.signInWithCustomToken(firebase_id_token);
        savePrivacyPolicy("policyAccepted", res?.is_consent_acknowledged);
        saveCustomerData(res.customer_details);
        if (!user) {
          reject(new Error("Failed to login. please try it again later"));
        }
        dispatch(authenticate());
        resolve(user);
      } catch (err) {
        reject(err);
      }
    });

const local_login =
  ({ email, password }) =>
  (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        const { user } = await auth.signInWithEmailAndPassword(email, password);
        if (!user)
          reject(new Error("Failed to login. please try it again later"));
        dispatch(authenticate());
        resolve(user);
      } catch (err) {
        reject(err);
      }
    });

const loginWithOtp = (firebase_id_token) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      const { user } = await auth.signInWithCustomToken(firebase_id_token);
      if (!user) {
        reject(new Error("Failed to login please try again later"));
      }
      dispatch(authenticate());
      resolve(user);
    } catch (err) {
      reject(err);
    }
  });

const logout = () => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      await auth.signOut();
      dispatch(
        slice.actions.setMe({
          checked: true,
          loggedIn: false,
          me: {},
        })
      );
      resolve();
      return true;
    } catch (err) {
      reject(err);
      return false;
    }
  });

const setTokenId = (tokenId) => (dispatch) =>
  dispatch(slice.actions.setSelectedToken({ tokenId }));

const setPolicyAccepted = (isPolicyAccepted) => (dispatch) =>
  dispatch(slice.actions.setIsPolicyAccepted({ isPolicyAccepted }));

export const actions = {
  ...slice.actions,
  authenticate,
  login,
  local_login,
  logout,
  setTokenId,
  loginWithOtp,
  setPolicyAccepted,
};

export default slice.reducer;
