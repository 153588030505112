import { lazy, Suspense, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import TermsAndConditions from "atomic-components/TermsAndConditions";
import { USERROLE } from "const/userRole";
import AuctionRoutes from "pages/auctioneer/AuctionRoutes";
// import useNotifications from "pages/auth/notification/pushNotification";
import BidderRoutes from "pages/bidder/BidderRoutes";
import Mandi from "services/common";
import AppUrlListener from "services/deeplinking/DeeplinkingListener";
import { actions } from "slices/app.slice";
import { actions as auctionActions } from "slices/auction.slice";
import { actions as catalogueActions } from "slices/catalogue.slice";
import { generateMap } from "utils";
import { getPrivacyPolicy, setMandiList } from "utils/localStorage";

import Fallback from "components/Fallback";
import Spinner from "components/Spinner";

import { NON_AUTH_ROUTES } from "./const";
import NonAuthenticatedRoutes from "./NonAuthenticatedRoutes";

const Auth = lazy(() => import("../pages/auth"));

const Router = () => {
  const dispatch = useDispatch();
  const { checked, loggedIn, me } = useSelector((state) => state.app);

  const { role } = me || {};

  const { pathname } = window.location;

  const handleGradeRangeInfo = () => {
    Mandi.getGradeAndRangeInfo().then((res) => {
      if (res.status) {
        const { responseData } = res || {};
        const { QUALITY_GRADES, RANGE_SIZE } = responseData;
        const QUALITY_GRADES_MAP = generateMap(QUALITY_GRADES, "value", "text");
        const RANGE_SIZE_MAP = generateMap(RANGE_SIZE, "value", "text");

        dispatch(
          catalogueActions.setGradeRangeFiltersData({
            QUALITY_GRADES,
            RANGE_SIZE,
            QUALITY_GRADES_MAP,
            RANGE_SIZE_MAP,
          })
        );
      }
    });
  };

  useEffect(() => {
    dispatch(actions.authenticate());
    handleGradeRangeInfo();
    Mandi.getMandiLists().then(({ responseData }) => {
      setMandiList(responseData);
    });
  }, []);

  useMemo(() => {
    if (loggedIn) {
      // enablePushNotifications();
    }
  }, [loggedIn]);

  if (!checked) {
    return (
      <div className="app-loader-container">
        <Spinner size="4rem" color="black" isLoading />
      </div>
    );
  }

  const checkForNonAuthRoutes = () => {
    for (let i = 0; i < NON_AUTH_ROUTES.length; i += 1) {
      const route = NON_AUTH_ROUTES[i];
      if (pathname.includes(route)) {
        return true;
      }
    }
  };

  const authenticatedRoutes = () => {
    if (!loggedIn) {
      return (
        <Routes>
          <Route path="/" element={<Auth />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      );
    }
    dispatch(auctionActions.loadAuctions());
    dispatch(auctionActions.loadAccessInfo(me.id));
    return (
      <>
        <div className="h-100">
          {role === USERROLE.BIDDER ? <BidderRoutes /> : <AuctionRoutes />}
        </div>
      </>
    );
  };

  const redirectTo = () => {
    if (checkForNonAuthRoutes()) {
      dispatch(auctionActions.loadAuctions());
      return <NonAuthenticatedRoutes />;
    }
    return authenticatedRoutes();
  };

  return (
    <BrowserRouter>
      <AppUrlListener />
      <Suspense fallback={<Fallback />}>{redirectTo()}</Suspense>
    </BrowserRouter>
  );
};

export default Router;
