import axios from "axios";
import _get from "lodash/get";
import eventManager from "utils/eventManager";
import { getUserData, removeUser, saveUserData } from "utils/localStorage";
import { notifySnackbar } from "utils/notify";

const serviceConfig = {
  timeout: 250000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  // crossDomain: true, // TODO
  // withCredentials: true,
};

const userObj = getUserData(); // TODO

const getServiceInstance = (baseURL) => {
  const serviceInstance = axios.create({
    ...serviceConfig,
    ...{ baseURL },
  });

  serviceInstance.CancelToken = axios.CancelToken;
  serviceInstance.isCancel = axios.isCancel;

  serviceInstance.interceptors.request.use((config) => {
    const modifiedConfig = { ...config };

    if (userObj?.ghostLogin && modifiedConfig?.method !== "get") {
      return Promise.reject(
        new Error("You are not allowed to perform this action as a ghost user.")
      );
    }

    if (baseURL === process.env.REACT_APP_MANDI_URL) {
      modifiedConfig.headers.Authorization =
        process.env.REACT_APP_MANDI_AUTH_TOKEN;
    }

    if (
      userObj &&
      userObj.token &&
      baseURL === process.env.REACT_APP_VELYNK_URL
    ) {
      modifiedConfig.headers.Authorization = userObj.token;
    } // TODO
    return modifiedConfig;
  });

  serviceInstance.interceptors.response.use(
    (response) => {
      if (response.headers.authorization) {
        const userObj = getUserData();
        saveUserData({ ...userObj, token: response.headers.authorization });
      }

      return response.data;
    },
    (error) => {
      if (serviceInstance.isCancel(error)) {
        return;
      }

      if (userObj?.ghostLogin) {
        return notifySnackbar(
          "You are not allowed to perform this action as a ghost user.",
          "error"
        );
      }

      eventManager.emit(
        "APP_ERROR",
        `${
          _get(error, "response.data.message") ||
          _get(error, "response.data.error") ||
          _get(error, "message")
        }`
      );

      if (
        error.config?.url !== "login" &&
        error.response &&
        error.response.status === 401
      ) {
        removeUser();
        window.location.href = "/";
        return;
      }
      throw error;
    }
  );
  return serviceInstance;
};

export const mandiService = getServiceInstance(process.env.REACT_APP_MANDI_URL);
export const velynkService = getServiceInstance(
  process.env.REACT_APP_VELYNK_URL
);
